import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import {
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';

import PartnerContactIcons from './PartnerContactIcons';

const PartnerCard = ({ partner, className, style }) => {
  return(
    <div className={`card partner-card has-background-light ${className}`} style={style}>
      {/*<Link to={`/${partner.slug}`}>
        <div className="card-image has-text-centered">
          <GatsbyImage className="image is-128x128 is-inline-block" image={getImage(partner.frontmatter.image)} alt="" />
        </div>
      </Link>*/}
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p><Link className="title is-size-4" to={`/${partner.slug}`}>{partner.frontmatter.partnerName}</Link></p>
            <p><PartnerContactIcons partner={partner}/></p>
            <p><span className="subtitle is-size-5">{partner.frontmatter.title}</span></p>
            <p>{partner.frontmatter.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerCard;
