import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import {
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';

const Modal = ({ children, closeModal, modalState }) => {
  if(!modalState) {
    return null;
  }
  
  return(
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-content">
        <div class="box">
        {children}
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" onClick={closeModal}></button>
    </div>
  );
}

class PartnerContactIcons extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      phoneModalState: false,
      emailModalState: false
    };
    
    this.togglePhoneModal = this.togglePhoneModal.bind(this);
    this.toggleEmailModal = this.toggleEmailModal.bind(this);

  }
  
  togglePhoneModal() {    
    this.setState((prev, props) => {
      const newState = !prev.phoneModalState;
      
      return { phoneModalState: newState };
    });
  }
  
  toggleEmailModal() {    
    this.setState((prev, props) => {
      const newState = !prev.emailModalState;
      
      return { emailModalState: newState };
    });
  }
  
  render() {
    const { partner } = this.props;
        
    return(
      <span className="icons">
        <a className="icon" onClick={this.toggleEmailModal}>
          <FontAwesomeIcon icon={faEnvelope} size="lg" />  
        </a>
        
        <Modal closeModal={this.toggleEmailModal} modalState={this.state.emailModalState}>
          <div className="content">
            <h1>E-Mail von {partner.frontmatter.partnerName}</h1>
            <p>
              <a href={`mailto:${partner.frontmatter.email}`} className="button">{partner.frontmatter.email}</a>
            </p>
            <h2>Vertrauliche Kommunikation</h2>
            <p>
              Sie können mir auch eine PGP-verschlüsselte E-Mail senden.
              Meinen PGP-Key finden Sie unter <a href={partner.frontmatter.pgp_keyfile.publicURL} download>{partner.frontmatter.pgp_fingerprint}</a>.
            </p>
            
            <p>
              Sofern Sie <a href="https://gnupg.org/">GnuPG</a> verwenden, importieren Sie den Schlüssel wie folgt:
              <pre><code>gpg --import {partner.frontmatter.pgp_keyfile.name}.{partner.frontmatter.pgp_keyfile.extension}</code></pre>
              Den Fingerprint {partner.frontmatter.pgp_fingerprint} können Sie wie folgt abgleichen:
              <pre><code>gpg --fingerprint --keyid-format LONG {partner.frontmatter.email}</code></pre>
            </p>
            
          </div>
        </Modal>
        
        <a className="icon" onClick={this.togglePhoneModal}>
          <FontAwesomeIcon icon={faPhone} size="lg" />  
        </a>
        
        <Modal closeModal={this.togglePhoneModal} modalState={this.state.phoneModalState}>
          <div className="content">
            <h1>Telefon von {partner.frontmatter.partnerName}</h1>
            <p>
              <a href={`tel:${partner.frontmatter.phone}`} className="button">{partner.frontmatter.phone}</a>
            </p>
            <h2>Vertrauliche Kommunikation</h2>
            <p>
              Sie können mich alternativ auch über <a href="https://signal.org/">Signal</a> anrufen. Gespräche über Signal sind Ende-zu-Ende-verschlüsselt und damit sicher.
              Legen Sie dazu einen Kontakt mit meiner Telefonnummer {partner.frontmatter.phone} auf Ihrem Mobiltelefon an. Anschließend finden Sie meinen Kontakt in der Signal-App. 
            </p>
            
          </div>
        </Modal>
        
        <a href={partner.frontmatter.linkedin} target="_blank" rel="noreferrer" className="icon">
          <FontAwesomeIcon icon={faLinkedin} size="lg" />  
        </a>
        
        <a href={partner.frontmatter.twitter} target="_blank" rel="noreferrer" className="icon">
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </a>
      </span>
    );
  }
}

export default PartnerContactIcons;