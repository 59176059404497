import React from 'react'
import { Link } from 'gatsby'

const CallToAction = ({ title, subtitle, className }) => {
  
  return (
    <section className={`section slant-top ${className}`} style={{background:"linear-gradient(to right, rgb(27,155,155), rgb(85,200,244))", filter:"saturate(0.7)"}}>
      <div className="container">
        <p className="title has-text-white" style={{textShadow:"0 0 0.2rem #000"}}>
          { title }
        </p>
        <p className="subtitle has-text-white mb-3" style={{textShadow:"0 0 0.2rem #000"}}>
          { subtitle }
        </p>
        <div className="block">
          <Link className="button" to="/kontakt">Setzen Sie sich mit uns in Verbindung<span className="triangle-right"></span></Link>
        </div>
      </div>
    </section>
  )
}

export default CallToAction


