import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from "@mdx-js/react"

import Layout from '../components/layout';
import PartnerCard from '../components/PartnerCard';
import CallToAction from '../components/CallToAction';

const LeistungPost = ({ location, data, pageContext }) => {
  
  const shortcodes = {CallToAction};
  
  return (
    <Layout title={data.mdx.frontmatter.title} description={data.mdx.excerpt} heroTitle={' '} imageSrc={data.mdx.frontmatter.hero_image.childImageSharp.gatsbyImageData.src} imageData={data.mdx.frontmatter.hero_image.childImageSharp.gatsbyImageData} pageContext={pageContext}>
      <section className="section">
        <div className="container">
          <div className="columns is-full">
            <div className="column has-text-left">
              <h1 className="title">
                {data.mdx.frontmatter.title}
              </h1>
            </div>
          </div>
          
          {/*<GatsbyImage className="image block" style={{maxHeight:"10rem"}} image={getImage(imageData)} />*/}
          
          <div className="content">
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </MDXProvider>
          </div>

          <div className="block">
            <h2 className="title">Ihre Ansprechpartner</h2>
            <div className="is-flex is-flex-wrap-wrap is-flex-align-items-stretch" style={{margin: "-0.75rem"}}>
            {
              data.mdx.frontmatter.authors.map((partner) => (
                <PartnerCard partner={partner} style={{margin: "0.75rem", flexBasis: "18rem"}} className="equal-height" />
              ))
            }
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`query AngebotByID($id: String) {
  mdx(id: {eq: $id}) {
    frontmatter {
      title
      hero_image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      hero_image_alt
      authors {
        frontmatter {
          partnerName
          description
          twitter
          linkedin
          email
          phone
          title
          pgp_keyfile {
            publicURL
            name
            extension
          }
          pgp_fingerprint
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        body
      }
    }
    body
    excerpt(pruneLength: 200)
  }
}
`;

export default LeistungPost;